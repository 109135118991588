import { LogLevel } from '@azure/msal-browser';

const environment = 'AZURE';

// 'https://providerrostercompare-uat.optum.com/'
// 'https://rostercompare-dev.optum.com/'
// 'https://uspprovidercompare.uhc.com/'

let redirectUri =
  environment === 'AZURE'
    ? 'https://rostercompare-dev.optum.com/'
    : 'http://localhost:3006/';

let hostUrl = '';
if (typeof window !== 'undefined') {
  hostUrl = window.location.href;
}

if (hostUrl.includes('home')) {
  redirectUri += 'home';
} else if (hostUrl.includes('UpdateNewMappedColumns')) {
  redirectUri += 'UpdateNewMappedColumns';
}

export const msalConfig = {
  auth: {
    clientId: '816b2f48-3ddc-4986-ba08-0a53cdb41918',
    authority:
      'https://login.microsoftonline.com/db05faca-c82a-4b9d-b9c5-0f64b6755421',
    redirectUri,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookies: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            return;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ['user.read'],
};

export const silentRequest = {
  scopes: ['user.read'],
  loginHint: 'example@domain.net',
};
